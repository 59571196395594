<template>
  <div :class="['sf-quantity-selector']">
    <SfButton
      class="sf-button--pure sf-quantity-selector__button"
      :disabled="disabled || Boolean(min !== null && qty <= min)"
      data-testid="decrease"
      @click="handleInput(Number(qty) - 1, $attrs, min, max)"
    >
      &minus;
    </SfButton>
    <SfInput
      class="sf-quantity-selector__input"
      type="number"
      :name="uniqueKey()"
      :modelValue="Number(qty)"
      :disabled="disabled"
      data-testid="sf-quantity-selector input"
      @update:modelValue="handleInput($event, $attrs, min, max)"
      @blur="handleBlur($attrs)"
    />
    <SfButton
      class="sf-button--pure sf-quantity-selector__button"
      :disabled="disabled || Boolean(max !== null && qty >= max)"
      data-testid="increase"
      @click="handleInput(Number(qty) + 1, $attrs, min, max)"
    >
      +
    </SfButton>
  </div>
</template>
<script setup>
const emit = defineEmits(['input'])

const props = defineProps({
  qty: {
    type: [Number, String],
    default: 1,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  min: {
    type: Number,
    default: null,
  },
  max: {
    type: Number,
    default: null,
  },
})

const handleInput = (qty, listeners, min, max) => {
  // adjust qty per min/max if needed
  const minimum = min || 1
  if (qty < minimum || isNaN(qty)) {
    qty = minimum
  } else if (max !== null && qty > max) {
    qty = max
  }
  return emit('input', qty)
}
const handleBlur = (listeners) => {
  return listeners.onBlur && listeners.onBlur()
}
const uniqueKey = () => {
  const key = Math.random().toString(16).slice(2)
  return 'quantitySelector' + key
}
</script>
<style lang="scss">
@use './styles/atoms/SfQuantitySelector.scss';
@use '~/storefront/assets/css/components/sf-quantity-selector';
</style>
